// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const IndexPage = () => {
  return (
     
    <div className={container}>

      <main>
        <Layout>

          <StaticImage src="../../static/header.jpg" alt="Get Into Investing! Header" layout="constrained" />

          <table align="center" width="90%">
            <tr>
              <td>
                <div><br />
                  <p><center><h2>Learn simple changes to your daily routine that help you understand investing, while earning more than a traditional savings account.</h2></center></p><br />

                  <p><h3><b><i>Get Into Investing!</i></b> teaches readers:</h3>
                    <p><li><b>Risk:</b> The design of the methods shown in the text allow readers to quickly assess what investments fit their needs, along with quickly sweeping aside any high-risk suggestions from outsiders.</li><br /></p>
                    <p><li><b>Safeguard Savings:</b> Don’t be scared of the stock market! Embrace its ability to build wealth with its various degrees of difficulty and market volatility. Not all investments lose and not all will make money. I show you how to make quick judgements and analyze the gains over time. </li><br /></p>
                    <p><li><b>Financial Security:</b> While some may think that everything in the market will do bad at the same time, there are always ways to find other sources of gains. <i>Get Into Investing!</i> shows readers how to manage their investment portfolio over time as it grows and they age.</li><br /></p>
                  </p>
                  </div>
                  <div align="center">
                    <p>
                    There are dozens of books on investing, but only <i>Get Into Investing!</i> is about how to successfully buy and sell stocks using today’s smartphone technology. The strategy uses three simple rules for investing, while taking only a few minutes a day and small contributions. Anyone of any age can easily invest in the stock market, resulting in the ability to earn far more than a traditional savings account.
                    <br />
                    <br />
                      <>  <Link className="btn btn-outline-secondary" to="/learn"> Learn </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/buy"> Buy Literature </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/social"> Social Media </Link> </>
                    </p>
                </div>
              </td>  
            </tr>
          </table>
        </Layout>

      </main>

    </div>
  )
}

// Step 3: Export your component
export default IndexPage